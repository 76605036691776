<template>
  <div class="apps">
    <b-alert dismissible :show="!emailVerified" variant="warning">
      <h4>Email not yet verified</h4>
      <p>
        Your access to this software will expire in
        {{ hoursUntilAccessExpires }} hours unless you verify your email address!<br />
        Please verify your email by clicking the link in the Welcome Email that we sent to you.
      </p>
    </b-alert>

    <b-container v-if="!errorMessage">
      <b-container v-if="listLoaded" class="mt-4">
        <b-row align-h="center" class="text-center">
          <b-col cols="10" md="7" lg="8">
            <h3>Use one of the links below to launch an app</h3>

            <hr />

            <h5 class="mt-5">
              {{ appList.length }} app<span v-if="appList.length != 1">s</span> are available:
            </h5>
          </b-col>
        </b-row>
        <b-row align-h="center" v-for="(app, index) in appList" :key="app.Name">
          <b-col class="mt-2" cols="10" sm="7" lg="6">
            <b-button block variant="outline-primary" @click="openApp(app, index)" class="px-3 pt-3 pb-3"
              target="_blank">
              <div :id="'loading-' + index" v-show="false" style="height: 32px"><b-spinner
                  variant="secondary"></b-spinner></div>
              <b-container :id="'app-' + index" class="font-weight-bold">
                <b-row>
                  <b-col sm="4" lg="1"><img :src="iconURL(app.iconUrl)" style="width: 32px; height: 32px"
                      alt="" /></b-col>
                  <b-col>{{ app.name }}</b-col>
                </b-row>
              </b-container>
            </b-button>
          </b-col>
        </b-row>
        <b-row align-h="center" style="text-align: right;">
          <b-col class="mt-2" cols="10" sm="8" lg="6">
            <b-link @click="init">Reload App List</b-link>
          </b-col>
        </b-row>
      </b-container>
      <!-- Only show this div when loading -->
      <div v-else class="text-center text-primary mt-5">
        <b-spinner variant="primary" label="Loading" class="mt-5"></b-spinner>
        <br /><br />
        <h5>Loading App List</h5>
        This may take up to 30 seconds...
      </div>
    </b-container>
    <!-- ...and only show this if there was a problem loading -->
    <b-container v-else class="my-4">
      <b-row align-h="center">
        <b-col cols="11" md="9" lg="8">
          <b-card header="Error Loading App List" border-variant="danger" header-text-variant="white"
            header-border-variant="danger" header-bg-variant="danger" header-class="font-weight-bold">
            <div class="text-center" v-html="errorMessage"></div>
            <hr class="mb-0" />
            <div class="mb-0 text-right">
              <b-link @click="init">Retry</b-link>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "AppList",
  components: {},
  data: function () {
    return {
      listLoaded: false,
      errorMessage: null,
      listLoadOK: true,
    };
  },
  mounted: function () {
    this.init()
  },
  computed: {
    appList: {
      get() {
        return this.$store.state.appList.applicationList;
      },
      set(value) {
        this.$store.commit("appList/setApplicationList", value);
      },
    },
    emailVerified: {
      get: function () {
        return !this.$store.state.auth.gracePeriod;
      },
    },
    accessDate: {
      get: function () {
        return Date.parse(this.$store.state.auth.graceUntil);
      },
    },
    hoursUntilAccessExpires: {
      get: function () {
        var milliseconds = this.accessDate - Date.now();
        return Math.floor(milliseconds / (1000 * 60 * 60));
      },
    },
  },
  watch: {},
  methods: {
    getUserDetails(reload) {
      this.$store.dispatch("users/fetchCurrentUserData", reload);
    },
    iconURL(key) {
      //return process.env.VUE_APP_CDN_URI + key
      return key
    },
    init() {
      // reset the variables
      this.appList = [];
      this.errorMessage = null;
      this.listLoaded = false;

      this.getUserDetails(false);
      // console.log(this.$store.state.auth.accessToken)
      this.$store
        .dispatch("appList/getAppsList", null)
        .then((response) => {
          this.appList = response;
          this.listLoaded = true;
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data) {
              this.errorMessage = "Sorry, something went wrong getting the apps list :(<br/>" + err.response.status;

              if (err.response.data.error)
                this.errorMessage += " " + err.response.data.error + " (Request ID: " + err.response.data.requestId + ")";
              else if (err.response.data.message)
                this.errorMessage += " " + err.response.data.message;
            }
            else {
              this.errorMessage = "The server encountered an error when sending the request: " + err.response.status;
            }
          }
          else if (err.request) {
            this.errorMessage = "The server didn't respond (" + err.message + ").";
          }
          else {
            this.errorMessage =
              "Something went wrong setting up the request (" + err.message + ").";
          }
        });
    },
    async openApp(app, index) {
      const tab = window.open('about:blank');
      document.getElementById('app-' + index).style.display = 'none';
      document.getElementById('loading-' + index).style.display = 'block';

      this.$store
        .dispatch("appList/openApp", app.streamingUrl)
        .then((response) => {
          tab.location = response.url;
          tab.focus();
        })
        .catch((err) => {
          tab.close();

          alert('Sorry, we couldn\'t open that app right now.');
          console.error('Unable to open app:', err);
        })
        .finally(() => {
          document.getElementById('loading-' + index).style.display = 'none';
          document.getElementById('app-' + index).style.display = 'block';
        });
    }
  },
};
</script>

<style>
#results {
  text-align: left;
}

.pointer {
  cursor: pointer;
}
</style>
